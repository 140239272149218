import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import ProductsList from '../../components/Products/ProductsList'
import Modal from '../../controls/Modal/Modal'
import EditProduct from '../../components/Products/EditProduct'
import AddProduct from '../../components/Products/AddProduct'
import { useNavigate } from "react-router-dom";
import Utils from '../../modules/utils';

function Products() {
  const util = new Utils();

  const [productsState, setproductsState] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [childModal, setchildModal] = useState(null);
  const [titleModal, settitleModal] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const navigate = useNavigate();

  
  useEffect(() => {

    (new API()).sendGETWithToken("/api/products", (result) => {
      setproductsState(result);
    })

  }, [])
  // useEffect(useCallback(() => {

  //   (new API()).sendGETWithToken("/api/products", (result) => {
  //     setproductsState(result);
  //   })

  // },[productsState]), [productsState,setSelectedAction])

  const AddProductClicked = (event) => {
    event.preventDefault();
    setSelectedProduct(null)
    settitleModal("افزودن محصول")
    setSelectedProduct({})
    setSelectedAction("add")
    setchildModal(<AddProduct item={null} changed={onSetProductUpdate} />)
    setShowModal(true)
  }
  const onSaveModal = (event) => {
    event.preventDefault();

    switch (selectedAction) {
      case "edit":
        if (selectedProduct) {
          (new API()).sendPatchWithToken(`/api/Products/${selectedProduct.id}`, selectedProduct, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedProduct(null)
            setShowModal(false)
              console.clear()
              setproductsState(result);
            
          })
        }
        break;
        case "add":
          if (selectedProduct) {
            (new API()).sendPostWithToken(`/api/products`, selectedProduct, (result) => {
              console.log(result);
              setSelectedAction(null)
              setSelectedProduct(null)
              setShowModal(false)
                console.clear()
                setproductsState(result);
              
            })
          }
          break;
  
      default:
        break;
    }




  }

  const closeProductClicked = (event) => {
    event.preventDefault();
    setSelectedProduct(null)
    setShowModal(false)
  }
  const onSetProductUpdate = (item) => {
    //event.preventDefault();
    console.log("VVV", item)
    setSelectedProduct(item)
  }
  const ProductItemClicked = (event, item, action) => {
    event.preventDefault();
    setSelectedProduct(null)
    switch (action) {
      case "delete":
        util.showDeleteMessage(() => {
          (new API()).sendPostWithToken(`/api/products/delete/${item.id}`, {}, (result) => {
            setproductsState([...result]);
            util.showAlert("تائید!", "اطلاعات تغییر یافت");
          })
        });
        break;
      case "edit":
        settitleModal("ویرایش محصول")
       
        setSelectedProduct(item)
        setSelectedAction(action)
        setchildModal(<EditProduct item={item} changed={onSetProductUpdate} />)
        setShowModal(true)
        break;
      case "packages":
        navigate(`/packages/${item.id}`)
        break;

      default:
        break;
    }
  }

  return (
    <PageWrapper>
      <PageTitle title="محصولات" />
      <Row>
        <Col size="lg-12">

          <PageCard>
            <div className="btn btn-list mb-2">
              <button className="btn ripple btn-primary" onClick={AddProductClicked}>
                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                محصول جدید </button>
            </div>
            <Table>
              <THead>
                <th className='w-25'>#</th>
                <th>کد</th>
                <th>عنوان</th>
                <th>نوع</th>
                <th>مبلغ ریالی</th>
                <th>مبلغ دلاری</th>
                <th>فعال</th>
              
              </THead>
              <tbody>
                {

                  productsState.map((item) => {
                    return <ProductsList item={item} click={ProductItemClicked} key={item.id}/>

                  })

                }
              </tbody>
            </Table>
            <Modal isShow={showModal} close={closeProductClicked} title={titleModal} saveClick={(event) => onSaveModal(event)}>
              {childModal}
            </Modal>
          </PageCard>
        </Col>
      </Row>

    </PageWrapper>

  )
}

export default Products