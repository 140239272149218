import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import LinkList from '../../components/Links/LinkList'
import Modal from '../../controls/Modal/Modal'
import EditLink from '../../components/Links/EditLink'
import AddLink from '../../components/Links/AddLink'
import { useNavigate } from "react-router-dom";

function Links() {

  const [linksState, setLinksState] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [childModal, setchildModal] = useState(null);
  const [titleModal, settitleModal] = useState("");
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {

    (new API()).sendGETWithToken("/api/links", (result) => {
      setLinksState(result);
    })

  }, [])

  const AddClicked = (event) => {
    event.preventDefault();
    setSelectedLink(null)
    settitleModal("افزودن محصول")
    setSelectedLink({})
    setSelectedAction("add")
    setchildModal(<AddLink item={null} changed={onSetLangUpdate} />)
    setShowModal(true)
  }
  const onSaveModal = (event) => {
    event.preventDefault();

    switch (selectedAction) {
      case "edit":
        if (selectedLink) {
          (new API()).sendPatchWithToken(`/api/links/${selectedLink.id}`, selectedLink, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedLink(null)
            setShowModal(false)
            console.clear()
            setLinksState(result);

          })
        }
        break;
      case "add":
        if (selectedLink) {
          (new API()).sendPostWithToken(`/api/links`, selectedLink, (result) => {
            console.log(result);
            setSelectedAction(null)
            setSelectedLink(null)
            setShowModal(false)
            console.clear()
            setLinksState(result);

          })
        }
        break;

      default:
        break;
    }




  }

  const closeLangClicked = (event) => {
    event.preventDefault();
    setSelectedLink(null)
    setShowModal(false)
  }
  const onSetLangUpdate = (item) => {
    //event.preventDefault();
    console.log("VVV", item)
    setSelectedLink(item)
  }
  const ItemClicked = (event, item, action) => {
    event.preventDefault();
    setSelectedLink(null)
    switch (action) {
      case "edit":
        settitleModal("ویرایش لینک")

        setSelectedLink(item)
        setSelectedAction(action)
        setchildModal(<EditLink item={item} changed={onSetLangUpdate} />)
        setShowModal(true)
        break;
    

      default:
        break;
    }
  }

  return (
    <PageWrapper>
      <PageTitle title="لینکها" />
      <Row>
        <Col size="lg-12">

          <PageCard>
            <div className="btn btn-list mb-2">
              <button className="btn ripple btn-primary" onClick={AddClicked}>
                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                لینک جدید </button>
            </div>
            <Table>
              <THead>
                <th className='w-25'>#</th>
                <th>کد</th>
                <th>عنوان</th>
                <th>نام گروه</th>
                <th>مسیر</th>

              </THead>
              <tbody>
                {

                  linksState.map((item) => {
                    return <LinkList item={item} click={ItemClicked} />

                  })

                }
              </tbody>
            </Table>
            <Modal isShow={showModal} close={closeLangClicked} title={titleModal} saveClick={(event) => onSaveModal(event)}>
              {childModal}
            </Modal>
          </PageCard>
        </Col>
      </Row>

    </PageWrapper>

  )
}

export default Links