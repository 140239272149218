import React from 'react'
import Wrapper from '../hoc/Wrapper/Wrapper'
import SideBar from '../components/template/SideBar/SideBar'
import Header from '../components/template/Header/Header'
import Footer from '../components/template/Footer/Footer'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/Home'
import Users from '../pages/Users/Users'
import Products from '../pages/Products/Products'
import Packages from '../pages/Packages/Packages'
import Langs from '../pages/Langs/Langs'
import Words from '../pages/Words/Words'
import Attachments from '../pages/Attachments/Attachments'
import HomeSettings from '../pages/Sites/Home/HomeSettings'
import SiteFooter from '../pages/Sites/Template/SiteFooter/SiteFooter'
import Links from '../pages/Links/Links'
import SiteHeader from '../pages/Sites/Template/SiteHeader/SiteHeader'
function Layout() {

	return (
		<Wrapper>
			<div className="page">
				<Router>
					<Wrapper>
						<SideBar />
						<Header />


						<Routes>


							<Route path="/" element={<Home />} />
							<Route path="/users" element={<Users />} />
							<Route path="/attachments" element={<Attachments />} />
							<Route path="/products" element={<Products />} />
							<Route path="/langs" element={<Langs />} />
							<Route path="/links" element={<Links />} />
							<Route path="/words/:id" element={<Words/>} />
							<Route path="/packages/:id" element={<Packages />} />
							<Route path="/home-site" element={<HomeSettings />} />
							<Route path="/site-footer" element={<SiteFooter />} />
							<Route path="/site-header" element={<SiteHeader />} />
							

						</Routes>



						<Footer />
					</Wrapper>
				</Router>
			</div>


		</Wrapper>
	)
}

export default Layout