import React from 'react'
import Utils from '../../modules/utils'
import { productTypeData } from '../../store/consts';

function ProductsList({ item,click }) {
  return (
    <tr key={item.id}>
      <td>

        <div class="btn-icon-list">
          <button class="btn ripple btn-info btn-icon" onClick={(event)=>click(event,item,"packages")}><i class="fe fe-list"></i></button>
          <button class="btn ripple btn-primary btn-icon" onClick={(event)=>click(event,item,"edit")}><i class="fe fe-edit"></i></button>
          <button class="btn ripple btn-secondary btn-icon" onClick={(event)=>click(event,item,"delete")}><i class="fe fe-trash"></i></button>
         
        </div>

      </td>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{productTypeData.filter((it)=>it.value==item.type)[0].label}</td>
      <td>{item.price_rial}</td>
      <td>{item.price_dollar}</td>
      <td><span className={item.is_active=='1'?'badge badge-primary badge-pill bg-success':'badge badge-primary badge-pill bg-danger'}><i className={item.is_active=='1'?'fa fa-check':'fa fa-times'}></i></span></td>


    </tr>

  )
}

export default ProductsList